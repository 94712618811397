// menu
.categorynemu {
    background-color: #fff;
    padding: 10px 0;

    .catgnemulist {
        display: flex;
        align-items: center;

        li {
            position: relative;

            &:last-child {
                &:after {
                    content: none;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 7px;
                right: -5px;
                background-image: url(/images/categories/menu-arrow.svg);
                background-repeat: no-repeat;
                width: 9px;
                height: 9px;
                background-size: contain;
            }

            a {
                color: #515151;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                display: inline-block;
                font-family: "robotoregular";
                padding: 0px 9px;

                &.active {
                    color: #acacac;
                }
            }
        }
    }
}

// sofa-set-banner
.sofabannersection {
    .sofabannerimg {
        display: block;

        img {
            width: 100%;
            height: auto;
        }

    }
}

// buttom-banner
.sofadiscountsection {
    margin: 30px 0px 0px;

    .discountimg {


        img {
            width: 100%;
            height: auto;
            display: block;
        }

    }

}

@media screen and (min-width: 410px) and (max-width: 430px) {

    .sofadiscountsection {

        .discountimg {
            height: 76px;
            min-height: 76px;
        }
    }
}

@media screen and (min-width: 385px) and (max-width: 410px) {
    .sofadiscountsection {
        .discountimg {
            min-height: 70px;
            height: 70px;
        }
    }
}


@media screen and (max-width:767px) {
    .sofadiscountsection {
        margin: 15px 0px 0px;
        padding-bottom: 10px;

        .discountimg {

            img {
                height: 100%;
            }
        }
    }
}




@media only screen and (max-width:320px) {
    .sofadiscountsection {
        .discountimg {
            height: 57px;
            min-height: 57px;
        }
    }
}